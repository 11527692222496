<template>
  <b-form
    @submit.prevent="postSynodData"
    @reset.prevent="OnReset"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Name"
          label-for="name"
          label-cols-md="4"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            type="text"
            placeholder="Name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Acronym"
          label-for="acronym"
          label-cols-md="4"
        >
          <b-form-input
            id="acronym"
            v-model="formData.acronym"
            type="text"
            placeholder="Acronym"
          />
        </b-form-group>
      </b-col>
      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      churchData: [],
      departmentData: [],
      positionData: [],
      formData: {},
    }
  },
  mounted() {
  },
  methods: {
    postSynodData() {
      this.$swal({
        title: 'Please Wait..',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 2000,
        didOpen: () => {
          this.$swal.showLoading()
        },
      }).then(
        axios.post('/synods', this.formData).then(res => {
          this.form = {}
          // eslint-disable-next-line no-console
          console.log('res', res)
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Your data has been saved.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.push({ name: 'synod-all' })
          })
        }),
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.5rem;
}
</style>
